import React, { useEffect } from 'react';

const TermsOfService = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#f0f4f8] py-12">
      <div className="container mx-auto px-4 pt-12">
        {/* Header Section */}
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Terms of Service</h2>
        <p className="text-center mb-4">Effective Date: October 1, 2024</p>

        {/* Terms of Service Content */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
          <h3 className="text-2xl font-semibold text-[#333] mb-4">Introduction</h3>
          <p className="text-[#333] mb-4">
            Welcome to Leads4Contact. These Terms of Service govern your use of our website and services. By accessing or using our services, you agree to comply with these terms. If you do not agree, please do not use our services.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Changes to Terms</h3>
          <p className="text-[#333] mb-4">
            We reserve the right to update or modify these Terms at any time without prior notice. Your continued use of our services after any changes indicates your acceptance of the new Terms.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Eligibility</h3>
          <p className="text-[#333] mb-4">
            You must be at least 13 years old to use our services. By using our services, you represent and warrant that you meet this eligibility requirement.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Account Registration</h3>
          <p className="text-[#333] mb-4">
            To access certain features of our services, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">User Conduct</h3>
          <p className="text-[#333] mb-4">
            You agree to use our services only for lawful purposes and in a manner that does not infringe the rights of others or restrict or inhibit anyone else's use of the services.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Intellectual Property</h3>
          <p className="text-[#333] mb-4">
            All content, trademarks, and other intellectual property on our website are the property of Leads4Contact or its licensors. You may not use, reproduce, or distribute any content without our express written permission.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Limitation of Liability</h3>
          <p className="text-[#333] mb-4">
            To the fullest extent permitted by law, Leads4Contact shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to your use of our services.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Indemnification</h3>
          <p className="text-[#333] mb-4">
            You agree to indemnify, defend, and hold harmless Leads4Contact and its affiliates from any claims, damages, losses, liabilities, costs, or expenses arising out of your use of our services or violation of these Terms.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Termination</h3>
          <p className="text-[#333] mb-4">
            We reserve the right to terminate or suspend your access to our services at any time, without prior notice, for conduct that we believe violates these Terms or is harmful to other users or us.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Governing Law</h3>
          <p className="text-[#333] mb-4">
            These Terms shall be governed by and construed in accordance with the laws of Pakistan. Any disputes arising from these Terms shall be resolved in the competent courts of Pakistan.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Contact Us</h3>
          <p className="text-[#333] mb-4">
            If you have any questions or concerns about these Terms, please contact us at:
          </p>
          <p className="text-[#333] mb-4">
            Email: <a href="mailto:contact@leads4contact.com" className="text-blue-500 underline">arehman@leads4contact.com</a>
          </p>
          <p className="text-[#333] mb-4">Phone: +92 3134250474</p>
        </div>

        <footer className="mt-8 text-center">
          <p className="text-gray-500">© 2024 Leads4Contact. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default TermsOfService;
