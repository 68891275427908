import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader'; // Import ClipLoader

const Career = () => {
  const [jobListings, setJobListings] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://www.leads4contact.com/backendnode/job');
        
        // Check if response.data is an array before setting the state
        if (Array.isArray(response.data)) {
          // Filter jobs whose status is true
          const activeJobs = response.data.filter(job => job.status === true);
          setJobListings(activeJobs);
        } else {
          console.error('Expected an array, but got:', response.data);
          setError('Failed to load job listings');
        }
      } catch (error) {
        console.error(error);
        setError('Error fetching job listings');
      } finally {
        setLoading(false); // Stop loading once the request is complete
      }
    };

    fetchJobs();
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <ClipLoader color="#3498db" loading={loading} size={50} />
      </div>
    ); // Show loading spinner
  }

  if (error) {
    return <div>{error}</div>; // Show error if there's an issue
  }

  return (
    <section className="bg-[#f0f4f8] pt-24 pb-12 min-h-screen">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Join Our Team</h2>
        <p className="text-lg text-center text-[#555] mb-12">
          We are always looking for talented professionals to join our team. If you're passionate about lead generation, data analysis, web research, or virtual assistant services, check out our open positions below.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {jobListings.length === 0 ? (
            <div className="col-span-full text-center text-lg text-gray-600">No active jobs available at the moment.</div>
          ) : (
            jobListings.map((job, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <h3 className="text-2xl font-semibold text-[#333] mb-2">{job.title}</h3>
                <p className="text-[#777] mb-4">{job.location}</p>
                <p className="text-[#555] mb-4">{job.description}</p>
                <h4 className="text-lg font-bold text-[#333] mb-2">Requirements:</h4>
                <ul className="list-disc list-inside text-[#555] mb-4">
                  {Array.isArray(job.requirements) ? (
                    job.requirements.map((requirement, idx) => (
                      <li key={idx}>{requirement}</li>
                    ))
                  ) : (
                    <p>No requirements listed</p>
                  )}
                </ul>
                <Link
                  to={`/applynow/${job._id}`}
                  className="inline-block bg-[#1a73e8] text-white px-4 py-2 rounded hover:bg-[#004d99] transition-colors duration-300"
                >
                  Apply Now
                </Link>
              </div>
            ))
          )}
        </div>

        <div className="text-center mt-12">
          <h3 className="text-3xl font-bold text-[#333] mb-4">Don’t see the right job?</h3>
          <p className="text-lg text-[#555] mb-6">We’re always looking for great talent. Feel free to send us your resume and tell us how you can make a difference!</p>
          <Link
            to="/contact"
            className="inline-block bg-[#38a0d9] text-white px-6 py-3 rounded hover:bg-[#1f5ea8] transition-colors duration-300"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Career;
