import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedin,
  faUpwork,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer className="bg-[#324666] text-white py-6">
      <div className="container mx-auto lg:px-14">
        {/* Main Content Section */}
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-start space-y-6 lg:space-y-0 items-center">
          {/* Left Section */}
          <div className="flex-1 text-center lg:text-left lg:pr-20">
            <h1 className="text-3xl font-bold">Leads4Contact</h1>
            <p className="text-lg text-[#e0e7f1]">Lead Generation Services</p>
            <p className="mt-4 text-[#d1d9e0]">
              At Leads4contact, we understand the <br /> value of high-quality
              leads for your business.
            </p>
            <Link to="/contact">
              <button className="mt-6 bg-[#2e70fe] hover:bg-[#1f5ea8] text-white px-4 py-3 rounded-lg text-lg font-semibold">
                Get a Quote <span className="ml-2">→</span>
              </button>
            </Link>
          </div>

          {/* Services Section */}
          <div className="flex-1 text-center lg:text-left">
            <h2 className="text-2xl font-semibold mt-2">Services</h2>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to="/services" className="hover:text-[#40f8ff]">
                  Leads Generation
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-[#40f8ff]">
                  Lead Enrichment
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-[#40f8ff]">
                  Web Research
                </Link>
              </li>
              <li>
                <Link
                  to="/services#lead-generation"
                  className="hover:text-[#40f8ff]"
                >
                  Email List Building
                </Link>
              </li>
              <li>
                <Link
                  to="/services#lead-enrichment"
                  className="hover:text-[#40f8ff]"
                >
                  Virtual Assistant
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="flex-1 text-center lg:text-left">
            <h2 className="text-2xl font-semibold mt-2 mb-3">Contact</h2>
            <h3 className="text-xl font-semibold">Phone</h3>
            <p className="text-white mb-2">+92 3134250474</p>
            <h3 className="text-xl font-semibold">Email</h3>
            <p className="text-white">
              <a
                href="mailto:arehman@leads4contact.com"
              >
                arehman@leads4contact.com
              </a>
            </p>

            {/* Social Media Links under Contact Section */}
            <div className="flex justify-center md:justify-start space-x-6 mt-4">
              <a
                href="https://www.facebook.com/leads4contact"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-[#38a0d9] text-2xl transition-transform transform hover:scale-110 duration-300"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.linkedin.com/company/leads4contact/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-[#0077b5] text-2xl transition-transform transform hover:scale-110 duration-300"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="https://www.upwork.com/agencies/425274009047953408/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-[#6fda44] text-2xl transition-transform transform hover:scale-110 duration-300"
              >
                <FontAwesomeIcon icon={faUpwork} />
              </a>
              <a
                href="#"
                className="text-white hover:text-black text-2xl transition-transform transform hover:scale-110 duration-300"
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-6 border-t border-[#1f5ea8] pt-6 flex flex-col lg:flex-row items-center justify-center lg:justify-between space-y-4 lg:space-y-0">
          {/* Part 1: Copyright */}
          <div className="flex-1 text-center lg:text-left text-[#e0e7f1]">
            &copy; 2024 Leads4Contact
          </div>

          {/* Part 2: Privacy Policy and Terms of Service */}
          <div className="flex-1 flex justify-center text-center space-x-2">
            <Link
              to="/privacy-policy"
              className="text-[#e0e7f1] hover:text-[#40f8ff]"
            >
              Privacy Policy
            </Link>
            <span className="text-[#e0e7f1]">|</span> {/* Separator line */}
            <Link
              to="/terms-of-service"
              className="text-[#e0e7f1] hover:text-[#40f8ff]"
            >
              Terms of Service
            </Link>
          </div>

          {/* Part 3: Designed by Prime-Soft-Solutions */}
          <div className="flex-1 text-center lg:text-right">
            <Link to="https://eprimesoftsolutions.com/" className="text-white">
              Designed by{" "}
              <span className="text-[#ea5757]">Prime-Soft-Solutions</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
