import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Get token from localStorage
  const authToken = localStorage.getItem('authToken');

  // You might also want to check if the token is valid (e.g., by decoding a JWT token to check expiration).
  const isTokenValid = () => {
    if (!authToken) return false;

    // Optionally: Decode the token to check its validity (e.g., expiration)
    try {
      const payload = JSON.parse(atob(authToken.split('.')[1]));
      const isExpired = payload.exp && payload.exp * 1000 < Date.now();
      return !isExpired;
    } catch (error) {
      console.error("Invalid token format", error);
      return false;
    }
  };

  // If token is missing or invalid, redirect to /signin
  if (!authToken || !isTokenValid()) {
    return <Navigate to="/signin" replace />;
  }

  // If authenticated, render the protected content
  return children;
};

export default ProtectedRoute;
