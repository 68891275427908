import React from 'react';
import client1 from './../../images/Nutanix-Logo-Charcoal-Gray-Digital.png';
import client2 from './../../images/rubiks.png';
import client3 from './../../images/Headway_Logo_Green_Logo.png';
import client4 from './../../images/marcus and millichap.png';
import client5 from './../../images/diDNA_logo.webp';
import client6 from './../../images/Hollas consulting.webp';
import client7 from './../../images/reachmail.svg';
import client8 from './../../images/trade-lanyards-logo-1536x425.png';
import client9 from './../../images/keller_william_logo.png';

const OurClients = () => {
  // Array of client logos
  const clients = [
    { id: 1, src: client1, alt: 'Nutanix Logo' },
    { id: 2, src: client2, alt: 'Rubiks Logo' },
    { id: 3, src: client3, alt: 'Headway Logo' },
    { id: 4, src: client4, alt: 'Marcus and Millichap Logo' },
    { id: 5, src: client5, alt: 'diDNA Logo' },
    { id: 6, src: client6, alt: 'Hollas Consulting Logo' },
   // { id: 7, src: client7, alt: 'Reachmail Logo' },
    { id: 8, src: client9, alt: 'Trade Lanyards Logo' },
    { id: 9, src: client8, alt: 'Keller Williams Logo' },
  ];

  return (
    <section id="clients" className="bg-[#f0f4f8] py-10 pt-6 pb-20">
      <div className="container mx-auto">
        <div className="section-header text-center mb-4">
          <h3 className="text-4xl font-bold text-[#283d50]">Our Clients</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 ">
          {clients.map((client) => (
            <div 
              key={client.id} 
              className="client-logo flex justify-center items-center bg-[#f0f4f8]  p-4 transform hover:scale-105" 
              // style={{
              //   boxShadow: '0 4px 8px 0 rgba(128, 164, 205, 0.6)', // Shadow with #80a4cd
              // }}
            >
              <img
                src={client.src}
                alt={client.alt}
                className="img-fluid transition-all duration-400 ease-in-out"
                style={{ maxHeight: '100px', maxWidth: '80%', objectFit: 'contain' }} // Responsive image style
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurClients;
