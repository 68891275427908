import React from 'react';
import img1 from '../../images/mrabdulrehman.png';
import img2 from '../../images/signature.png';

const FounderWords = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-8 px-4"> 
      {/* Left Column: Video */}
      <div className="relative w-full md:w-4/5 mx-auto" style={{ paddingTop: '56.25%' }}> 
        <iframe 
          className="absolute inset-0 w-full h-full rounded-lg"
          src="https://www.youtube.com/embed/ogL9fdN0GUw?si=bWMksleas5CncYsD"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen>
        </iframe>
      </div>

      {/* Right Column: Text */}
      <div className="flex flex-col justify-center p-8">
        
        {/* Section Title */}
        <div className="mb-4">
          <h2 className="text-sm text-[#00a] uppercase font-semibold mb-1">Know About Us</h2>
          <h1 className="text-3xl font-bold text-black">
            About <span className="text-[#3882f6]">Leads4Contact</span>
          </h1>
        </div>

        {/* Main Content */}
        <p className="text-base text-[#555] leading-relaxed mb-4">
        At Leads4contact, we specialize in empowering businesses by providing top-tier lead generation and enrichment services. For over 10 years, we’ve focused on helping companies grow by offering data-driven, customized lead solutions tailored to their unique needs. Whether you're looking to expand your outreach, optimize customer engagement, or build a comprehensive lead database, our team delivers results with precision and accuracy. We’re proud to be a trusted partner for businesses looking to scale through quality lead acquisition and actionable insights.
        </p>
        <p className="text-base text-[#555] leading-relaxed mb-8">
        At Leads4contact, we don’t just generate leads, we build relationships. With over a decade of experience, our focus is on understanding your business goals and aligning our solutions to help you succeed.
        </p>

        {/* Founder Signature Section */}
        <div className="flex items-center mt-8">
          <img 
            src={img1}
            alt="Abdul Rehman" 
            className="w-20 h-18 rounded-full mr-2" 
          />
          <div>
          <img 
            // src='https://signaturegenerate.com/wp-admin/admin-ajax.php?action=get_signature&text=Abdul%20Rehman&font=Autography-DOLnW.otf'
            src={img2}
            alt="Abdul Rehman" 
            className="w-30 h-12" 
          />
            {/* <p className="text-2xl font-handwriting text-[#3882f6]">Abdul Rehman</p> */}
            <p className="text-sm text-[#555] ml-2 mt-2">Co-Founder</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FounderWords;
