import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: ''
  });
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_k4a44zf',    // Replace with your EmailJS service ID
        'template_17x6yj9',   // Replace with your EmailJS template ID
        e.target,             // Form data
        'iMgvQMQ4m81-tW9lt'   // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          // Success feedback message
          setStatusMessage('Your message has been sent successfully! We will get back to you soon.');
          setStatusType('success');
          // Clear the form
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: ''
          });
        },
        (error) => {
          // Error feedback message
          setStatusMessage('Oops! Something went wrong. Please try again later.');
          setStatusType('error');
        }
      );
  };

  return (
    <div className="bg-[#f4f7f9] min-h-screen flex flex-col items-center py-12 px-4">
      <h2 className="text-4xl font-bold text-[#17315c] text-center mt-12 mb-8">Contact Us</h2>

      {/* Contact Us Form Section */}
      <div className="w-full max-w-3xl bg-white p-8 rounded-lg shadow-lg mb-12 border border-gray-200">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-[#333] text-sm font-semibold mb-2" htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg bg-[#f9f9f9] text-[#333] border border-[#ddd] focus:outline-none focus:ring-2 focus:ring-[#38a0d9]"
                placeholder="Your Name"
                required
              />
            </div>
            <div>
              <label className="block text-[#333] text-sm font-semibold mb-2" htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg bg-[#f9f9f9] text-[#333] border border-[#ddd] focus:outline-none focus:ring-2 focus:ring-[#38a0d9]"
                placeholder="Your Email"
                required
              />
            </div>
            <div>
              <label className="block text-[#333] text-sm font-semibold mb-2" htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg bg-[#f9f9f9] text-[#333] border border-[#ddd] focus:outline-none focus:ring-2 focus:ring-[#38a0d9]"
                placeholder="Your Phone Number"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-[#333] text-sm font-semibold mb-2" htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-[#f9f9f9] text-[#333] border border-[#ddd] focus:outline-none focus:ring-2 focus:ring-[#38a0d9] h-32"
              placeholder="Your Message"
              required
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-[#38a0d9] hover:bg-[#1f5ea8] text-white font-bold py-3 px-6 rounded-lg transition duration-300"
            >
              Send Message
            </button>
          </div>
        </form>

        {/* Status Message */}
        {statusMessage && (
          <div
            className={`mt-6 text-center py-3 px-6 rounded-lg ${statusType === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}
          >
            {statusMessage}
          </div>
        )}
      </div>

      {/* Company Details Section */}
      <div className="w-full max-w-5xl bg-white p-8 rounded-lg shadow-lg border border-gray-200 space-y-8">
        <h2 className="text-3xl font-semibold text-[#17315c] mb-6 text-center">Company Details</h2>

        {/* Phone, Email, Location, and Google Map Section in a Row */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 text-center">
          {/* Phone Section */}
          <div className="flex flex-col items-center">
            <i className="fas fa-phone-alt text-[#38a0d9] text-4xl mb-4"></i>
            <h3 className="text-xl font-semibold text-[#17315c] mb-2">Phone</h3>
            <p className="text-[#555]">+92 3134250474</p>
          </div>

          {/* Email Section */}
          <div className="flex flex-col items-center">
            <i className="fas fa-envelope text-[#38a0d9] text-4xl mb-4"></i>
            <h3 className="text-xl font-semibold text-[#17315c] mb-2">Email</h3>
            <p className="text-[#555]">arehman@leads4contact.com</p>
          </div>

          {/* Location Section */}
          <div className="flex flex-col items-center">
            <i className="fas fa-map-marker-alt text-[#38a0d9] text-4xl mb-4"></i>
            <h3 className="text-xl font-semibold text-[#17315c] mb-2">Location</h3>
            <p className="text-[#555]">Azeem Commercial Zone, Begum Kot Shahdara Town, Lahore, Punjab, Pakistan</p>
          </div>

          {/* Google Map Section */}
          <div className="flex flex-col items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6795.4671057229225!2d74.26338200000001!3d31.61376!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191db488b7150d%3A0x5ea609765dd03fb4!2sLeads4contact!5e0!3m2!1sen!2sus!4v1725894827483!5m2!1sen!2sus"
              width="100%"
              height="100"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Location"
              className="rounded-lg shadow-md"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
