import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const testimonials = [
  {
    name: 'Joshua Chernikoff',
    feedback: 'Leads4contact has always handled themselves in a professional way. The work they do has been spot on and done in a very timely manner. Highly recommended!',
    role: 'CEO',
  },
  {
    name: 'Angie Yamaguchi',
    feedback: 'Wonderful to work with Leads4contact! They are great at keeping in communication and going above & beyond what was asked of them.',
    role: 'Co-Founder',
  },
  {
    name: 'Stephen Barone',
    feedback: 'Leads4contact always performed great work at a fast pace. I\'d recommend them to anybody looking for quality work and good communication.',
    role: 'CEO',
  },
  {
    name: 'Matthew Emery',
    feedback: 'Leads4contact delivers 100% valid contact information. Highly recommend them for LinkedIn lead generation and web research projects.',
    role: 'CEO',
  },
  {
    name: 'Mark Felling',
    feedback: 'Leads4contact does very quality work! They work extremely fast and diligently. I would recommend them 100%. We absolutely loved working with them!',
    role: 'Co-Founder',
  },
  {
    name: 'Michael Fischer',
    feedback: 'Did great work, responsive, accurate, and quick. Highly skilled and knowledgeable team. Highly recommended.',
    role: 'CEO',
  },
  {
    name: 'Gina Ruscio',
    feedback: 'I hired Leads4contact to work on several data entry and data scraping tasks for this project. The team is fantastic! They work quickly and efficiently with high accuracy.',
    role: 'Co-Founder',
  },
  {
    name: 'Ashlee Rogers',
    feedback: 'Leads4contact works hard and regularly. They have improved significantly over time, aiming to please with attention to detail. Fair and hardworking team.',
    role: 'CEO',
  },
  {
    name: 'Sean Jones',
    feedback: 'Leads4contact works regularly and is detail-oriented. They aim to please and have greatly improved over time. A highly recommended team.',
    role: 'CEO',
  },
  {
    name: 'Emily Johnson',
    feedback: 'An outstanding experience with Leads4contact! They consistently deliver high-quality leads and are always on top of their game.',
    role: 'Co-Founder',
  },
  {
    name: 'Daniel Smith',
    feedback: 'Leads4contact provided exceptional service and delivered results beyond expectations. They are a reliable partner for all our lead generation needs.',
    role: 'CEO',
  },
];

const ClientTestimonialsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="bg-[#eef2f6] shadow-lg rounded-lg pt-8 px-4 pb-3">
      <h3 className="text-3xl font-bold text-[#17315c] text-center mb-8">What Our Clients Say</h3>
      
      {/* Carousel Container */}
      <div className="relative overflow-hidden">
        <div
          className="flex transition-transform duration-1000 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * (100 / (window.innerWidth < 768 ? 1 : 3))}%)`
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              className={`w-full px-4 mb-4 ${window.innerWidth >= 768 ? 'md:w-1/3' : ''}`}
              key={index}
              style={{ flexShrink: 0 }}
            >
              <div className="bg-[#e0e7f1] rounded-lg p-4 h-full flex flex-col items-center shadow-md">
                {/* Feedback */}
                <p className="text-[#333] text-md md:text-lg lg:text-md mb-4 text-center leading-relaxed">"{testimonial.feedback}"</p>

                {/* Name and Role */}
                <h4 className="font-bold text-xl md:text-xl lg:text-xl text-[#17315c] mb-1">{testimonial.name}</h4>
                <p className="text-[#999] text-base md:text-md lg:text-lg mb-4">{testimonial.role}</p>

                {/* Star Rating */}
                <div className="flex mb-0">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <FontAwesomeIcon
                        icon={faStar}
                        className="text-[#f5c242] text-lg mr-1"
                        key={i}
                      />
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center p-4">
        {testimonials.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full mx-2 ${currentIndex === index ? 'bg-[#1a73e8]' : 'bg-gray-300'}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ClientTestimonialsCarousel;
