import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedin,
  faUpwork,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from './../images/logo.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const location = useLocation();
  const [aboutTimeout, setAboutTimeout] = useState(null);
  const [servicesTimeout, setServicesTimeout] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdowns = () => {
    if (aboutTimeout) clearTimeout(aboutTimeout);
    if (servicesTimeout) clearTimeout(servicesTimeout);
    setAboutDropdownOpen(false);
    setServicesDropdownOpen(false);
  };

  const handleMouseEnterAbout = () => {
    if (aboutTimeout) clearTimeout(aboutTimeout);
    setAboutDropdownOpen(true);
  };

  const handleMouseLeaveAbout = () => {
    setAboutTimeout(setTimeout(closeDropdowns, 500)); // Delay before closing
  };

  const handleMouseEnterServices = () => {
    if (servicesTimeout) clearTimeout(servicesTimeout);
    setServicesDropdownOpen(true);
  };

  const handleMouseLeaveServices = () => {
    setServicesTimeout(setTimeout(closeDropdowns, 500)); // Delay before closing
  };

  const navbarBackground =
    location.pathname === '/' && scrollPosition < 100
      ? 'bg-gradient-to-r from-[#0e1f38] to-[#50688d] shadow-xl'
      : 'bg-gradient-to-r from-[#50688d] to-[#0e1f38] shadow-xl';

  return (
    <nav
      className={`fixed top-0 left-0 w-full ${navbarBackground} text-[#333] py-4 px-6 flex items-center justify-between transition-transform duration-300 z-50`}
      style={{
        height: '4rem',
      }}
    >
      {/* <Link to="/" className="flex items-center space-x-2 mr-12 ml-4">
        <span className="text-white text-2xl md:text-3xl font-bold">Leads4Contact</span>
      </Link> */}


      <Link to="/" className="flex items-center space-x-2">
        <img src={logo} alt="Logo" className="h-12" />
      </Link>

      {/* Centered Navigation Links */}
      <div className="flex-1 hidden md:flex justify-center">
        <div className="flex space-x-8">
          <Link to="/" className="text-white text-lg hover:text-[#40f8ff]">Home</Link>
          <div
            className="relative"
            ref={aboutRef}
            onMouseEnter={handleMouseEnterAbout}
            onMouseLeave={handleMouseLeaveAbout}
          >
            <Link
              to="/about"
              className="text-white text-lg hover:text-[#40f8ff]"
            >
              About
              <svg className="w-2.5 h-2.5 ms-3 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
              </svg>
            </Link>
            {aboutDropdownOpen && (
              <div className="absolute left-0 mt-2 w-40 bg-white divide-y divide-gray-100 rounded-lg shadow z-10">
                <ul className="py-2 text-sm text-gray-700">
                <li>
                    <Link to="/team" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setAboutDropdownOpen(false)}>Team</Link>
                  </li>
                  <li>
                    <Link to="/career" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setAboutDropdownOpen(false)}>Career</Link>
                  </li>
                 
                </ul>
              </div>
            )}
          </div>

          <div
            className="relative"
            ref={servicesRef}
            onMouseEnter={handleMouseEnterServices}
            onMouseLeave={handleMouseLeaveServices}
          >
            <Link
              to="/services"
              className="text-white text-lg hover:text-[#40f8ff]"
            >
              Services
              <svg className="w-2.5 h-2.5 ms-3 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
              </svg>
            </Link>
            {servicesDropdownOpen && (
              <div className="absolute left-0 mt-2 w-60 bg-white divide-y divide-gray-100 rounded-lg shadow z-10">
                <ul className="py-2 text-sm text-gray-700">
                  <li>
                    <Link to="/services" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setServicesDropdownOpen(false)}>Lead Generation</Link>
                  </li>
                  <li>
                    <Link to="/services" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setServicesDropdownOpen(false)}>Data Enrichment</Link>
                  </li>
                  <li>
                    <Link to="/services" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setServicesDropdownOpen(false)}>Web Research</Link>
                  </li>
                  <li>
                    <Link to="/services" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setServicesDropdownOpen(false)}>Email Marketing</Link>
                  </li>
                  <li>
                    <Link to="/services" className="block px-4 py-2 hover:bg-[#18335d] hover:text-white" onClick={() => setServicesDropdownOpen(false)}>Virtual Assistant</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <Link to="/projects" className="text-white text-lg hover:text-[#40f8ff]">Projects</Link>
          <Link to="/contact" className="text-white text-lg hover:text-[#40f8ff]">Contact Us</Link>
        </div>
      </div>

      {/* Demo Leads button & Social Media Icons */}
      <div className="hidden md:flex items-center space-x-4 mr-4">
        <div className="flex space-x-3">
          <a href="https://www.facebook.com/leads4contact" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} className="text-white hover:text-[#4267B2] transition duration-300" />
          </a>
          <a href="https://www.linkedin.com/company/leads4contact/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="text-white hover:text-[#0e76a8] transition duration-300" />
          </a>
          <a href="https://www.upwork.com/agencies/425274009047953408/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faUpwork} className="text-white hover:text-[#6fda44] transition duration-300" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} className="text-white hover:text-[black] transition duration-300" />
          </a>
        </div>
        <Link
          to="/contact"
          className="bg-[#2e70fe] text-white px-4 py-2 rounded-lg hover:bg-[#1f5ea8] transition duration-300"
        >
          Get Demo Leads!
        </Link>
      </div>

      {/* Mobile Menu Icon */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-white">
          {isOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
  <div className="md:hidden absolute top-16 left-0 w-full bg-gradient-to-r from-[#50688d] to-[#0e1f38] z-20">
    <div className="flex flex-col space-y-4 p-4 items-center">
      <Link to="/" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>Home</Link>
      <Link to="/about" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>About</Link>
      <Link to="/services" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>Services</Link>
      <Link to="/projects" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>Projects</Link>
      <Link to="/contact" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>Contact Us</Link>
      <Link to="/career" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>Career</Link>
      <Link to="/team" className="text-white text-lg hover:text-[#40f8ff]" onClick={toggleMenu}>Team</Link>

      <Link
        to="/contact"
        className="bg-[#2e70fe] text-white px-6 py-3 rounded-lg hover:bg-[#1f5ea8] transition duration-300"
        onClick={toggleMenu}
      >
        Get Demo Leads!
      </Link>
    </div>
  </div>
)}

    </nav>
  );
};

export default Navbar;
