import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faStar, faHandshake, faChartLine, faUsers, faLightbulb } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-[#f0f4f8] py-12">
      {/* Background Image */}
      <div
        className="absolute inset-0 opacity-10 bg-cover bg-center"
        style={{ backgroundImage: `url('/path-to-your-background-image.jpg')` }}
      ></div>

      <div className="container relative mx-auto px-6 lg:px-16">
        <br /><br />
        {/* Who We Are Section */}
        <h2 className="text-4xl font-bold text-[#17315c] text-center ">Who We Are</h2>
       

        {/* New Section: Video and Text */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-8">
          {/* Left Column: Video */}
          <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/ogL9fdN0GUw?si=bWMksleas5CncYsD"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          {/* Right Column: Text */}
          <div className="flex flex-col justify-center p-8 bg-[#e0e7f1]  shadow-lg">
            <p className="text-lg text-[#555] leading-relaxed">
              We are a Top Rated Upwork Agency specializing in generating quality leads in various niches. Our team consists of seasoned professionals who excel in lead generation, data enrichment, email scraping, B2B marketing, web scraping, data mining, and more. With over a decade of experience, we've helped businesses worldwide thrive by delivering over 1M high-quality leads across diverse industries.
            </p>
            <p className="text-lg text-[#555] leading-relaxed mt-6">
              Our mission is simple: to empower businesses with the data they need to grow, succeed, and outpace the competition. We take pride in our professionalism, accuracy, and commitment to excellence.
            </p>
          </div>
        </div>

        {/* Vision & Values Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-12">
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 text-center hover:bg-[#e0e7f1] transition duration-300">
            <FontAwesomeIcon icon={faBullseye} size="3x" className="text-[#38a0d9] mb-4" />
            <h3 className="text-3xl font-semibold text-[#333] mb-4">Our Vision</h3>
            <p className="text-lg text-[#555]">
              To be a global leader in lead generation and data management, driving success for businesses through
              innovative and tailored solutions.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg hover:bg-[#e0e7f1] transition duration-300 hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 text-center">
            <FontAwesomeIcon icon={faStar} size="3x" className="text-[#38a0d9] mb-4" />
            <h3 className="text-3xl font-semibold text-[#333] mb-4">Our Values</h3>
            <ul className="list-disc list-inside text-lg text-[#555] space-y-2 text-left">
              <li>Professionalism</li>
              <li>Accuracy</li>
              <li>Commitment to Excellence</li>
              <li>Client Satisfaction</li>
              <li>Innovation</li>
              <li>Integrity</li>
            </ul>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg hover:bg-[#e0e7f1] transition duration-300 hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 text-center">
            <FontAwesomeIcon icon={faChartLine} size="3x" className="text-[#38a0d9] mb-4" />
            <h3 className="text-3xl font-semibold text-[#333] mb-4">Our Achievements</h3>
            <p className="text-lg text-[#555]">
              Over 1M leads generated, 942 projects completed, 833 happy clients, and more than 100k hours of work
              delivered to date.
            </p>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="mt-16 bg-[#e0e7f1] p-12 rounded-lg shadow-lg text-center">
          <FontAwesomeIcon icon={faHandshake} size="3x" className="text-[#38a0d9] mb-4" />
          <h3 className="text-4xl font-semibold text-[#333] mb-4">Why Choose Us?</h3>
          <p className="text-lg text-[#555] max-w-3xl mx-auto mb-8">
            We believe in delivering results that go beyond expectations. Our team of experts is committed to providing
            precise, high-quality data that empowers your business to reach new heights. With a track record of success
            and a dedication to innovation, we are your go-to partner for all things lead generation and data management.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-12">
            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 text-center">
              <FontAwesomeIcon icon={faUsers} size="3x" className="text-[#38a0d9] mb-4" />
              <h4 className="text-2xl font-semibold text-[#333] mb-4">Experienced Team</h4>
              <p className="text-lg text-[#555]">
                Our team of experts brings years of experience and a proven track record of delivering results that
                matter.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 text-center">
              <FontAwesomeIcon icon={faLightbulb} size="3x" className="text-[#38a0d9] mb-4" />
              <h4 className="text-2xl font-semibold text-[#333] mb-4">Innovative Solutions</h4>
              <p className="text-lg text-[#555]">
                We leverage cutting-edge technology and innovative strategies to deliver data solutions that give your
                business an edge.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
