import React from 'react';
import {Link} from 'react-router-dom'

const services = [
  {
    title: 'Lead Generation',
    description: 'We deliver high-quality, targeted leads tailored to your business needs, helping you reach potential clients and drive growth.',
    icon: '🔍', // Placeholder icon; replace with actual SVGs or images.
  },
  {
    title: 'Data Enrichment',
    description: 'Our data enrichment services enhance your existing data by verifying and updating key information, ensuring accuracy and better decision-making.',
    icon: '📊', // You can replace this with an appropriate icon
},

  {
    title: 'Web Research',
    description: 'We provide thorough web research services to gather detailed, relevant insights, empowering your business with valuable data for informed strategies.',
    icon: '💻',
  },
  {
    title: 'Email Marketing',
    description: 'Craft and execute personalized email campaigns designed to engage your audience, boost conversions, and strengthen customer relationships.',
    icon: '📧', // Use an email-related icon
  },
];

const OurServices = () => {
  return (
    <section className="bg-[#eef2f6] py-12 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-8">
          {/* Left Column: Text Content */}
          <div className='mr-20'>
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">What We’re Offering</h2>
            <h1 className="mt-2 text-3xl leading-9 font-extrabold text-[#17315c] sm:text-4xl sm:leading-10">
              Services We're Providing
            </h1>
            <p className="mt-4 text-lg leading-7 text-gray-500">
            Leads4Contact is a top-rated Upwork agency renowned for its expertise in lead generation across various niches. Our seasoned professionals specialize in lead generation, data enrichment, email scraping, B2B marketing, web scraping, and data mining. With over a decade of experience, we have successfully delivered more than 1 million high-quality leads, empowering businesses worldwide to achieve remarkable growth.
            </p>
            <p className="mt-4 text-lg leading-7 text-gray-500">
            Our mission is to equip you with the essential data needed for success and competitive advantage. We pride ourselves on our professionalism, accuracy, and unwavering commitment to excellence.


            </p>
            <div className="text-left mt-8">
              <Link to='/services' className="inline-block bg-[#18335d] text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-[#18335d]" >
                
              
                View All Services ➡</Link>
                
              
            </div>
          </div>

          {/* Right Column: Services Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ml-10">
            {services.map((service, index) => (
                <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg p-6 flex flex-col justify-between transition-shadow duration-300  "
                style={{
                    minHeight: '300px', // Ensures consistent height for all boxes
                    boxShadow: '0 4px 10px rgba(31, 94, 168)', // Custom shadow color with shade of #18335d
                    backgroundColor: '#e0e7f1',
                }}
                >
                <div className="mb-6">
                    <div className="text-5xl text-blue-500 mb-4">{service.icon}</div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">{service.title}</h3>
                    <p className="text-gray-600 text-base">{service.description}</p>
                </div>
                </div>
            ))}
            </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
