import React from 'react';

const FullScreenSection = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="flex flex-col md:flex-row w-full max-w-4xl justify-center space-y-4 md:space-y-0 md:space-x-4 p-4">
        {/* Left Square Section */}
        <div className="flex-1 aspect-square border-4 border-blue-500 rounded-lg flex items-center justify-center bg-white shadow-lg h-[400px] md:h-auto">
          <h2 className="text-lg font-bold text-blue-500">Left Section</h2>
        </div>

        {/* Right Square Section */}
        <div className="flex-1 aspect-square border-4 border-blue-500 rounded-lg flex items-center justify-center bg-white shadow-lg h-[400px] md:h-auto">
          <h2 className="text-lg font-bold text-blue-500">Right Section</h2>
        </div>
      </div>
    </div>
  );
};

export default FullScreenSection;
