import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { useParams } from 'react-router-dom';

const ApplyNow = () => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const { jobId } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadFileAndSendEmail = async (e) => {
    e.preventDefault();
    setStatusMessage('');
    setIsError(false);

    const fileInput = form.current.querySelector('#resume');
    const file = fileInput.files[0];

    if (!file) {
      setStatusMessage('Please upload your resume.');
      setIsError(true);
      return;
    }

    const formData = new FormData();
    formData.append('resume', file);
    formData.append('fullName', e.target.user_name.value);
    formData.append('email', e.target.user_email.value);
    formData.append('coverLetter', e.target.message.value);
    formData.append('jobId', jobId); // Add job ID here

    try {
      console.log(formData)
      // Step 1: Upload the file and other data to the backend
      const uploadResponse = await axios.post('https://www.leads4contact.com/backendnode/jobApplicant/applicants', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      

      // const { fileLink } = uploadResponse.data; // Get the file link from backend response

      // // Step 2: Send email using EmailJS
      // const emailData = {
      //   user_name: e.target.user_name.value,
      //   user_email: e.target.user_email.value,
      //   message: e.target.message.value,
      //   resume_link: fileLink, // Include the link to the uploaded file
      //   job_id: jobId, // Include the job ID in the email data
      // };

      // const emailResponse = await emailjs.send(
      //   'service_lzi3zol',
      //   'template_adhokbb',
      //   emailData,
      //   'xrwJT1OiS5kl2w_q3'
      // );

      setStatusMessage('Application sent successfully!');
      setIsError(false);
      //console.log(emailResponse.text);
      e.target.reset();
    } catch (error) {
      console.error('Application sending failed:', error);
      setStatusMessage('An error occurred while sending your application. Please try again.');
      setIsError(true);
    }
  };

  return (
    <section className="bg-[#f0f4f8] py-8 md:py-12 flex flex-col items-center min-h-screen mt-11">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0">
          <div className="md:w-1/2 text-center md:text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-[#17315c] mb-4">Join Our Team!</h2>
            <p className="text-lg md:text-xl text-[#777] mb-8">
              We're always on the lookout for talented individuals. Apply now!
            </p>
          </div>
          <div className="md:w-1/2">
            <form
              ref={form}
              onSubmit={uploadFileAndSendEmail}
              className="bg-white p-6 shadow-md rounded-lg border border-gray-200"
            >
              {statusMessage && (
                <div
                  className={`p-4 mb-4 rounded-lg ${isError ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}
                >
                  {statusMessage}
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="user_name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                    required
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="user_email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="user_email"
                    name="user_email"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="message">
                  Cover Letter
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="resume">
                  Upload Resume
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-[#38a0d9] hover:bg-[#1f5ea8] text-white font-semibold py-2 px-6 rounded-lg transition-colors duration-300"
                >
                  Submit Application
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplyNow;
