import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader'; // Import ClipLoader for the spinner

const EditJob = () => {
  const [job, setJob] = useState({
    title: '',
    location: '',
    description: '',
    requirements: [],
    status: false, // Add status field to the job object
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`https://www.leads4contact.com/backendnode/job/${jobId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        });
        setJob(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching job details:', err);
        setError('Unable to retrieve job details. Please try again later.');
        setLoading(false);
      }
    };
    fetchJob();
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob(prevJob => ({ ...prevJob, [name]: value }));
  };

  const handleRequirementChange = (e, index) => {
    const newRequirements = [...job.requirements];
    newRequirements[index] = e.target.value;
    setJob(prevJob => ({ ...prevJob, requirements: newRequirements }));
  };

  const addRequirement = () => {
    setJob(prevJob => ({ ...prevJob, requirements: [...prevJob.requirements, ''] }));
  };

  const handleStatusChange = (e) => {
    const { checked } = e.target;
    setJob(prevJob => ({ ...prevJob, status: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start the loading state when submitting
    try {
      await axios.put(`https://www.leads4contact.com/backendnode/job/${jobId}`, job, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        }
      });
      setMessage('Job has been successfully updated.');
      setMessageType('success');
      setTimeout(() => {
        navigate('/addjob');
      }, 2000); 
    } catch (err) {
      console.error('Error updating job:', err);
      setMessage('There was an issue updating the job. Please try again.');
      setMessageType('error');
    } finally {
      setLoading(false); // Stop the loading state when request finishes
    }
  };

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center">
      <ClipLoader color="#3498db" loading={loading} size={50} />
    </div>
  ); // Show spinner while loading

  if (error) return <p className="text-center text-red-600">{error}</p>;

  return (
    <section className="bg-[#f0f4f8] py-12 min-h-screen">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 rounded-lg shadow-md border border-gray-200 max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold text-[#17315c] mb-6">Edit Job Details</h2>
          {message && (
            <div
              className={`mb-4 p-4 border rounded-lg ${messageType === 'success' ? 'bg-green-100 text-green-700 border-green-300' : 'bg-red-100 text-red-700 border-red-300'}`}
            >
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="title">
                Job Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={job.title}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="location">
                Job Location
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={job.location}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="description">
                Job Description
              </label>
              <textarea
                id="description"
                name="description"
                value={job.description}
                onChange={handleChange}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9]"
                rows="4"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2">Job Requirements</label>
              {job.requirements.map((req, index) => (
                <div key={index} className="flex items-center mb-3">
                  <input
                    type="text"
                    value={req}
                    onChange={(e) => handleRequirementChange(e, index)}
                    className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#38a0d9] mr-2"
                    placeholder={`Requirement ${index + 1}`}
                  />
                  <button
                    type="button"
                    onClick={() => setJob(prevJob => ({
                      ...prevJob,
                      requirements: job.requirements.filter((_, i) => i !== index)
                    }))}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-300"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addRequirement}
                className="bg-[#38a0d9] text-white px-4 py-2 rounded-lg hover:bg-[#1f5ea8] transition-colors duration-300"
              >
                Add Another Requirement
              </button>
            </div>
            
            {/* Status checkbox for active/inactive job */}
            <div className="mb-6">
              <label className="block text-lg font-semibold text-[#333] mb-2" htmlFor="status">
                Job Status (Active/Inactive)
              </label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="status"
                  name="status"
                  checked={job.status}
                  onChange={handleStatusChange}
                  className="mr-2"
                />
                <span>{job.status ? 'Active' : 'Inactive'}</span>
              </div>
            </div>

            <button
              type="submit"
              className="bg-[#38a0d9] text-white px-6 py-3 rounded-lg hover:bg-[#1f5ea8] transition-colors duration-300 w-full"
            >
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditJob;
