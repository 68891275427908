import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Popup = ({ title, description, image, process, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-1/2 relative">
        {/* Close Button */}
        <button onClick={onClose} className="absolute top-3 right-4 text-[#38a0d9] hover:text-gray-800">
          <FontAwesomeIcon icon={faXmark} size="lg" />
        </button>

        <h3 className="text-2xl font-semibold mb-4">{title}</h3>

        {/* Image will fit completely inside the div without cropping */}
        <div className="w-full h-48 mb-4">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-contain rounded-lg"
          />
        </div>

        <p className="text-gray-700 mb-4">{description}</p>
        <h4 className="font-bold mb-2">Service Process:</h4>
        <ul className="list-disc list-inside mb-4">
          {process.map((item, index) => (
            <li key={index} className="text-gray-600">{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Popup;
