// import React, { useState, useEffect } from "react";
// import { useInView } from "react-intersection-observer";
// import { FaProjectDiagram, FaSmile, FaTasks, FaClock } from "react-icons/fa";

// const animateValue = (setter, target, duration) => {
//   const start = performance.now();
//   const step = (timestamp) => {
//     const progress = Math.min((timestamp - start) / duration, 1);
//     setter(Math.round(progress * target));
//     if (progress < 1) {
//       requestAnimationFrame(step);
//     }
//   };
//   requestAnimationFrame(step);
// };

// const SuccessThroughTheYears = () => {
//   const [projectCount, setProjectCount] = useState(0);
//   const [clientCount, setClientCount] = useState(0);
//   const [runningProjects, setRunningProjects] = useState(0);
//   const [upworkHours, setUpworkHours] = useState(0);
//   const[projectRunning,setProjectRunning]=useState(0);
//   const { ref, inView } = useInView({
//     triggerOnce: true, // Only trigger animation once
//     threshold: 0.1, // Trigger when 10% of the component is in view
//   });

//   useEffect(() => {
//     if (inView) {
//       animateValue(setProjectCount, 867, 3000);
//       animateValue(setClientCount, 334, 3000);
//       animateValue(setProjectRunning, 7, 3000);
//       animateValue(setUpworkHours, 135000, 3000);
//     }
//   }, [inView]);

//   return (
//     <div ref={ref} className="bg-[#e0e7f1] py-16 rounded-r-full relative shadow-lg  mr-16 mb-8"
//     style={{
//       minHeight: '300px', // Ensures consistent height for all boxes
//       boxShadow: '0 4px 10px rgba(31, 94, 168)', // Custom shadow color with shade of #18335d
//       }}>
//       <div className="text-center mb-8">
//         <h2 className="text-3xl font-semibold text-[#333] mb-4">
//           Here are some of the snapshot of our success over the years.
//         </h2>
//         <hr className="w-16 mx-auto border-t-4 border-[#1f5ea8]" />
//       </div>

//       <div className="flex justify-center gap-12 font-semibold text-[#333] ">
//       <div className="flex flex-col items-center">
//           <div className="relative w-28 h-28 mb-4">
//             <div className="absolute inset-0 rounded-full border-4 border-[#1f5ea8]" />
//             <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
//               {projectCount}+
//             </div>
//           </div>
//           <p className="text-gray-600">Project Done</p>
//         </div>

//         <div className="flex flex-col items-center">
//           <div className="relative w-28 h-28 mb-4">
//             <div className="absolute inset-0 rounded-full border-4 border-[#1f5ea8]" />
//             <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
//               {projectRunning}+
//             </div>
//           </div>
//           <p className="text-gray-600">Project Running</p>
//         </div>

//         <div className="flex flex-col items-center">
//           <div className="relative w-28 h-28 mb-4">
//             <div className="absolute inset-0 rounded-full border-4 border-[#1f5ea8]" />
//             <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
//               {clientCount}+
//             </div>
//           </div>
//           <p className="text-gray-600">Total Clients Served</p>
//         </div>

//         <div className="flex flex-col items-center">
//           <div className="relative w-40 h-28 mb-4">
//             <div className="absolute inset-0 rounded-full border-4 border-[#1f5ea8]" />
//             <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
//               {upworkHours.toLocaleString()}+
//             </div>
//           </div>
//           <p className="text-gray-600">Upwork Hours</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SuccessThroughTheYears;


import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const animateValue = (setter, target, duration) => {
  const start = performance.now();
  const step = (timestamp) => {
    const progress = Math.min((timestamp - start) / duration, 1);
    setter(Math.round(progress * target));
    if (progress < 1) {
      requestAnimationFrame(step);
    }
  };
  requestAnimationFrame(step);
};

const SuccessThroughTheYears = () => {
  const [projectCount, setProjectCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [runningProjects, setRunningProjects] = useState(0);
  const [upworkHours, setUpworkHours] = useState(0);
  const [projectRunning, setProjectRunning] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      animateValue(setProjectCount, 867, 3000);
      animateValue(setClientCount, 334, 3000);
      animateValue(setProjectRunning, 7, 3000);
      animateValue(setUpworkHours, 135000, 3000);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className="bg-[#e0e7f1] py-16 rounded-lg relative shadow-lg mx-4 md:mx-16 mb-8"
      style={{
        minHeight: '300px',
        boxShadow: '0 4px 10px rgba(31, 94, 168)',
      }}
    >
      <div className="text-center mb-8">
        <h2 className="text-3xl font-semibold text-[#333] mb-4">
          Snapshot of Our Success Over the Years
        </h2>
        <hr className="w-16 mx-auto border-t-4 border-[#1f5ea8]" />
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 font-semibold text-[#333]">
        {[
          { label: "Projects Done", value: projectCount },
          { label: "Projects Running", value: projectRunning },
          { label: "Total Clients Served", value: clientCount },
          { label: "Upwork Hours", value: upworkHours.toLocaleString() },
        ].map(({ label, value }, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="relative w-32 h-32 mb-4"> {/* Increased size for better fit */}
              <div className="absolute inset-0 rounded-full border-4 border-[#1f5ea8] transition-transform transform hover:scale-105" />
              <div className="absolute inset-0 flex items-center justify-center text-xl md:text-2xl font-bold">
                {value}+
              </div>
            </div>
            <p className="text-gray-600 text-lg text-center">{label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessThroughTheYears;
