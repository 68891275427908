import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

const JobApplicant = () => {
  const { jobId } = useParams();
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [applicantToDelete, setApplicantToDelete] = useState(null); // Applicant to delete

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await axios.get(`https://www.leads4contact.com/backendnode/jobApplicant/applicants/job/${jobId}`);
        setApplicants(response.data);
      } catch (error) {
        setError('Error fetching applicants');
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [jobId]);

  const handleDelete = async () => {
    if (!applicantToDelete) return;

    try {
      await axios.delete(`https://www.leads4contact.com/backendnode/jobApplicant/applicants/${applicantToDelete._id}`);
      setApplicants((prevApplicants) => prevApplicants.filter((applicant) => applicant._id !== applicantToDelete._id));
      setIsModalOpen(false); // Close modal after deletion
    } catch (error) {
      console.error('Error deleting applicant:', error);
      setError('Error deleting applicant');
      setIsModalOpen(false);
    }
  };

  const handleDeleteClick = (applicant) => {
    setApplicantToDelete(applicant);
    setIsModalOpen(true); // Open modal on delete click
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal without deleting
    setApplicantToDelete(null); // Reset applicant to delete
  };

  if (loading)
    return (
      <div className="min-h-screen flex items-center justify-center">
        <ClipLoader color="#3498db" loading={loading} size={50} />
      </div>
    );

  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center">
        {error}
      </div>
    );

  return (
    <div className="flex flex-col min-h-screen">
      <div className="container mx-auto py-8 flex-grow">
        <h2 className="text-2xl font-bold mb-6">Job Applicants</h2>
        {applicants.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b text-left text-gray-700 font-medium">Name</th>
                <th className="py-2 px-4 border-b text-left text-gray-700 font-medium">Email</th>
                <th className="py-2 px-4 border-b text-left text-gray-700 font-medium">Cover Letter</th>
                <th className="py-2 px-4 border-b text-left text-gray-700 font-medium">Resume</th>
                <th className="py-2 px-4 border-b text-left text-gray-700 font-medium">Date & Time</th>
                <th className="py-2 px-4 border-b text-left text-gray-700 font-medium">Actions</th>
              </tr>
            </thead>
            <tbody>
              {applicants.map((applicant) => (
                <tr key={applicant._id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b">{applicant.fullName}</td>
                  <td className="py-2 px-4 border-b">{applicant.email}</td>
                  <td className="py-2 px-4 border-b">{applicant.coverLetter}</td>
                  <td className="py-2 px-4 border-b">
                    {applicant.resume ? (
                      <a
                        href={`https://www.leads4contact.com/backendnode/${applicant.resume}`}
                        download
                        className="text-blue-600 hover:underline"
                      >
                        Download Resume
                      </a>
                    ) : (
                      'No Resume'
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">{new Date(applicant.createdAt).toLocaleString()}</td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={() => handleDeleteClick(applicant)}
                      className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No applicants found for this job.</div>
        )}
      </div>

      {/* Custom Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 transition-opacity duration-300 ease-in-out">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm transform transition-transform duration-500 scale-95 hover:scale-100">
            <h3 className="text-xl font-semibold mb-4">Confirm Deletion</h3>
            <p className="mb-6">Are you sure you want to delete this applicant?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCloseModal}
                className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-all duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobApplicant;
